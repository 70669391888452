import {BrowserRouter, Route, Routes} from "react-router-dom";
import {Amplify, DataStore, Hub} from 'aws-amplify'
import {Authenticator, ThemeProvider, useTheme, View} from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import awsExports from "./aws-exports";
import {studioTheme} from "./ui-components";
import {EditPage, MainPage, NavBar} from "./my-components";

awsExports.oauth.redirectSignIn = `${window.location.origin}/`;
awsExports.oauth.redirectSignOut = `${window.location.origin}/`;

Amplify.configure(awsExports);

Hub.listen('auth', async (data) => {
    if (data.payload.event === 'signOut') {
        await DataStore.clear();
    }
});

const App = () => {
    const {tokens} = useTheme();

    return (
        <Authenticator socialProviders={['google']} variation={"modal"} hideSignUp={true}>
            {({signOut, user}) => (
                <ThemeProvider theme={studioTheme}>
                    <View maxWidth="500px"
                          margin="0 auto"
                          minHeight="100vh"
                          padding={tokens.space.small}
                          backgroundColor={tokens.colors.background.primary}
                    >
                        <BrowserRouter>
                            <NavBar user={user} signOut={signOut}/>
                            <main>
                                <Routes>
                                    <Route path="/" element={<MainPage/>}/>
                                    <Route path="/edit" element={<EditPage/>}/>
                                </Routes>
                            </main>
                        </BrowserRouter>
                    </View>
                </ThemeProvider>
            )}
        </Authenticator>
    )
}
export default App
