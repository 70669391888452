import {Card, Flex, Icon, Text, TextAreaField, TextField, useTheme, View} from '@aws-amplify/ui-react';
import {DataStore} from "aws-amplify";
import {BreakItem} from "../models";

const TrashIcon = () => <Icon
    width="20px"
    height="24px"
    viewBox={{width: 20, height: 24}}
    pathData="M1.14286 17.7778C1.14286 19 2.17143 20 3.42857 20L12.5714 20C13.8286 20 14.8571 19 14.8571 17.7778L14.8571 4.44444L1.14286 4.44444L1.14286 17.7778ZM3.95429 9.86667L5.56571 8.3L8 10.6556L10.4229 8.3L12.0343 9.86667L9.61143 12.2222L12.0343 14.5778L10.4229 16.1444L8 13.7889L5.57714 16.1444L3.96571 14.5778L6.38857 12.2222L3.95429 9.86667ZM12 1.11111L10.8571 0L5.14286 0L4 1.11111L0 1.11111L0 3.33333L16 3.33333L16 1.11111L12 1.11111Z"
/>;


export default function BreakCard(props: any) {
    const {tokens} = useTheme();
    const breakItem = props.breakItem;
    const isEditable = props.isEditable || false;

    async function removeBreakItem(breakItem: BreakItem) {
        try {
            await DataStore.delete(BreakItem, breakItem.id);
        } catch (err) {
            console.log('error deleting breakItem:', err)
        }
    }

    async function saveBreakItem({name, description}: { name?: string, description?: string }) {
        try {
            await DataStore.save(
                BreakItem.copyOf(breakItem, updated => {
                    updated.name = name || breakItem.name;
                    updated.description = description || breakItem.description;
                })
            );
        } catch (err) {
            console.log('error updating breakItem:', err)
        }
    }

    const NameField = () => {
        if (isEditable) {
            return <TextField
                label="Name"
                labelHidden
                variation="quiet"
                onBlur={(e) => saveBreakItem({name: e.target.value})}
                defaultValue={breakItem?.name}
                inputStyles={{
                    fontSize: tokens.fontSizes.large,
                    fontWeight: tokens.fontWeights.bold,
                    padding: '0px',
                    border: 'none',
                }}
            />
        } else {
            return <Text
                fontSize={tokens.fontSizes.large}
                fontWeight={tokens.fontWeights.bold}
                children={breakItem?.name}
            />
        }
    }

    const DescriptionField = () => {
        if (isEditable) {
            return <TextAreaField
                label="Description"
                labelHidden
                variation="quiet"
                rows={Math.ceil(breakItem.description?.length / 100) || 1}
                size="small"
                wrap="soft"
                width="100%"
                defaultValue={breakItem.description}
                onBlur={(e) => saveBreakItem({description: e.target.value})}
                inputStyles={{
                    padding: '0px',
                    border: 'none'
                }}
            />
        } else {
            return <Text children={breakItem?.description}/>
        }
    }

    return <View backgroundColor={tokens.colors.background.tertiary}>
        <Card backgroundColor={tokens.colors.brand.primary[20]} borderRadius="10px">
            <Flex direction="row" justifyContent="space-between">
                <Flex direction="column" alignItems="flex-start" gap={tokens.space.xs}>
                    <NameField/>
                    <DescriptionField/>
                </Flex>
                {isEditable && <View onClick={() => removeBreakItem(breakItem)}>
                    <TrashIcon/>
                </View>}
            </Flex>
        </Card>
    </View>
};