import {useEffect, useState} from "react";
import {Button, Flex, useTheme, View} from '@aws-amplify/ui-react';
import {BreakItem} from "../models";
import {DataStore} from 'aws-amplify';
import BreakCard from "./BreakCard";
import './BreakCard.css';


export default function MainPage() {
    const {tokens} = useTheme();
    const [currentBreakItem, setCurrentBreakItem] = useState<BreakItem | undefined>(undefined);
    const [breakItems, setBreakItems] = useState<BreakItem[]>([]);


    function setRandomBreakItem() {
        setCurrentBreakItem(breakItems[Math.floor(Math.random() * breakItems.length)])
        const view = document.getElementById('breakItem')
        if (view) {
            view.style.animation = "spin 1s ease-out 1";
            new Promise(resolve => setTimeout(resolve, 1000)).then(() => {
                view.style.animation = "";
            })
        }

    }

    useEffect(() => {
        DataStore.query(BreakItem).then(setBreakItems);
    }, []);

    useEffect(() => {
        if (breakItems.length > 0) {
            setCurrentBreakItem(breakItems[Math.floor(Math.random() * breakItems.length)]);
        }
    }, [breakItems]);

    return <View
        margin="20px auto"
        borderRadius="10px"
        padding={tokens.space.medium}
        backgroundColor={tokens.colors.background.secondary}
    >
        <Flex
            direction="column"
            margin="0 auto"
            maxWidth="400px"
            justifyContent="center"
            alignItems="center"
            height="400px"
            gap={tokens.space.xxl}
        >
            {currentBreakItem && <View id="breakItem" width="100%">
                <BreakCard breakItem={currentBreakItem}/>
            </View>
            }
            <Button
                width="120px"
                variation="primary"
                fontSize={tokens.fontSizes.xxl}

                onClick={e => {
                    e.currentTarget.blur();
                    setRandomBreakItem();
                }}
                borderRadius="15px"
            >
                Go!
            </Button>
        </Flex>
    </View>
}