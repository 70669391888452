import {NavLink} from 'react-router-dom';
import {Button, Divider, Flex, Menu, MenuItem, useTheme} from '@aws-amplify/ui-react';

export default function AddBreak(props: any) {
    const {user, signOut} = props;
    const email = user?.attributes?.email;
    const {tokens} = useTheme();

    return <Flex direction="row" justifyContent="space-between" alignContent="center">
        <Flex direction="row">
            <NavLink to="/" style={{ textDecoration: 'none' }}>
                <Button variation="menu">
                    Home
                </Button>
            </NavLink>
            <NavLink to="/edit" style={{ textDecoration: 'none' }}>
                <Button variation="menu">
                    Edit
                </Button>
            </NavLink>
        </Flex>
        <Menu menuAlign="end">
            <MenuItem fontWeight={tokens.fontWeights.normal} fontSize={tokens.fontSizes.small}
                      isDisabled>{email}</MenuItem>
            <Divider/>
            <MenuItem onClick={signOut}>Sign out</MenuItem>
        </Menu>
    </Flex>
}