import {useDataStoreBinding} from "@aws-amplify/ui-react/internal";
import {DataStore} from "aws-amplify";
import {Button, Collection, Divider, Flex, useTheme, View} from '@aws-amplify/ui-react';
import {BreakItem} from "../models";
import BreakCard from "./BreakCard";


export default function EditPage() {
    const {tokens} = useTheme();

    const breakItems: BreakItem[] = useDataStoreBinding({
        type: "collection",
        model: BreakItem,
    }).items.sort(
        (a, b) => {
            if (a.createdAt == null) return -1
            else if (b.createdAt == null) return 1
            else return b.createdAt.localeCompare(a.createdAt)
        }
    ) || []

    const createBreakItem = async () => {
        const breakItem = new BreakItem({
            name: "Name",
            description: "Description"
        })
        try {
            await DataStore.save(breakItem);
        } catch (err) {
            console.log('error creating breakItem:', err)
        }
    }

    const BreakCardCollection = () => {
        return (
            <View>
                <View marginTop={tokens.space.medium} marginBottom={tokens.space.large}>
                    <Divider/>
                </View>
                <Collection
                    type="list"
                    direction="column"
                    alignItems="center"
                    items={breakItems}
                >
                    {(item, _) => (
                        <View width="100%">
                            <BreakCard
                                breakItem={item}
                                key={item.id}
                                isEditable={true}
                            />
                        </View>
                    )}
                </Collection>
            </View>
        )
    }

    return <View
        margin="20px auto"
        borderRadius="10px"
        padding={tokens.space.medium}
        backgroundColor={tokens.colors.background.secondary}
    >
        <Flex
            direction="column"
            maxWidth="400px"
            margin="0 auto"
        >
            <Button onClick={() => createBreakItem()}>Add Break Activity</Button>
            <BreakCardCollection/>
        </Flex>
    </View>
}